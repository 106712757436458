<template>
 <div class="container bg-info">
        <p class="text-white">
        Local installations
        </p>
        <div class="container">
          
          <div class="row">
            <div class="col"><img alt="Jet Dock Wisconsin"  class="img-fluid p-1" :src="img23"></div>
            <div class="col"><img alt="Jet Dock Wisconsin"  class="img-fluid p-1" :src="img24"></div>
          </div>
          <div class="row">
            <div class="col"><img alt="Jet Dock Wisconsin"  class="img-fluid p-1" :src="img25"></div>
            <div class="col"><img alt="Jet Dock Wisconsin"  class="img-fluid p-1" :src="img26"></div>
          </div>
          <div class="row">
            <div class="col"><img alt="Jet Dock Wisconsin"  class="img-fluid p-1" :src="img27"></div>
            <!-- <div class="col"><img alt="Jet Dock Wisconsin"  class="img-fluid p-1" :src="img9"></div> -->
          </div>
          <div class="row">
            <div class="col"><img alt="Jet Dock Wisconsin"  class="img-fluid p-1" :src="img21"></div>
            <div class="col"><img alt="Jet Dock Wisconsin"  class="img-fluid p-1" :src="img22"></div>
          </div>
        </div>
 </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

export default ({
  data () {
    return {
      img21: "https://dsm01pap006files.storage.live.com/y4m1fqPX3G5kwoyGvInhttNw3eILi73fukMyUVp_yTYL5zehJcHgylJk2_iF-a1TaZhi8Hz7gD5lnPOwkt0kxStaQhMgDL7Gci1Cqx_78Tr1628PRfQI9qrviY8HA2824BGqUc7shkKYEzQZmW45uC4qYRDWHwlkdfTyOxsYptXqMi1UwzSbxmUBxlT8Hd9WtuK?width=192&height=256&cropmode=none",
      img22: "https://dsm01pap006files.storage.live.com/y4mHpWnzFWLFvpSJr0D11HQCIn2JzSxR1mybFhZS3Bt1aQK0GMxCwCxvAIhgaciNDYwRgW-Jv1YvD0joMtUZhkl2nLG0EwQqFPHEf-lahrMVi3cqpUTPClqvH33ngUyljcdGdnstmcTDQzz15udbO5T8OgMbV-l3oWg_2dILtuz3LaP7fYrRkfP1q8bSkpY3df_?width=768&height=256&cropmode=none",
      img23: "https://dsm01pap006files.storage.live.com/y4mFfZ8HL1LcVgFUJHraT7RonCzHZQeOuBcU0jNQKSv_ojo5DDpmiyLWWSpS2bfy1Kite9FXQygWQRR5_DD6LWNwrowSDNpcblov2OrnzRMTJ6ORpjcuKQtEC_r0jCp1H1eVO49iyMxZG2iEjGNV58alxHVrrzuj8XqOGV3MiQYaYOh08pXCLAR06OlQEw4m8tB?width=192&height=256&cropmode=none",
      img24: "https://dsm01pap006files.storage.live.com/y4mMk8gTU99-s_fYsTIV_NvhWwQlr7R4SRk2ZbENKY2KOC2JmJwIQqDdeN6heZpCsxzbukP7A-PJ-9jfHqdPKbQtg_vwMCyW4XoMtCeIKAcjJJV9KpyJKdb1srshdT8Mbs5Il7UICSH3i9LRWzt2QzWlzGYyYV0cbpavQNVvm5o_IhRX560VjECBDnHoVslXyT2?width=256&height=192&cropmode=none",
      img25: "https://dsm01pap006files.storage.live.com/y4meodmbfEz5jQOcWnaxvmHW5DOAXd2ZTTlXTaD44HqJf61-YLtW2LDEXsaeBaVlmRawl_A0GHQT7HqOFoy825-KqV4BaeQ0CU2ymi5tz3EV40Ugj5IPPJzdUVn2PLuZGHC0PNCSPJ7G5Oe1OcPNHGFQ9m7SFi3WBG67p3jxnc8R_IkBROtfH5CuyodvreUSa6m?width=256&height=192&cropmode=none",
      img26: "https://dsm01pap006files.storage.live.com/y4mRqNeOrg5stP9Ml49GU-R44ayJOSE2OB0Dtfk8egsuAu7ap-5ie_CvYE6gstxv5HEL3IBHilHDKumfNlz3eFGJD0c-N7txd1qJg836rXWB2Es-xBo1Y49svkxOIAnuUMF1fe5Z_wOiPyg7eZSm6suLINbQKHWAG9ZiqEx2iY82Fr1KoABIa7Q9Cx2ck1B04FU?width=256&height=192&cropmode=none",
      img27: "https://dsm01pap006files.storage.live.com/y4mMLeyHQdNH8pKY13G5yWfuv4ZJYBrLePer01WTc0PgLKhvuXsAbqOo-6RK6aktx2ZbdYLe7vJA2KHzkT4IqZTduYpa9c0ao16Yqc0-i5G0Z2TkFJMPWo0FcUpQXqgpFhMxdFVwFJfLJ6O6o5hV6cuq85CUI2pRFJQy2sj5lo6DOUsEo5LbkEgz51tCIfhjxIk?width=256&height=172&cropmode=none"
      // img1: "https://dsm01pap006files.storage.live.com/y4m5HmarQrjEfHTEssII4Eay0cubl9osPQaybRZhJ6Ct3PhhjyF36oh8j-KUrD8oV9d6SGsS5_AZ_1gmSOqU1KEQCR8QHAwS6aGZIjo0zXsBa7Dl3HVnN7MqqhNes73y3NcYyfE7UcNCPqF12egUqjqDYHKHg_ZHc4Z1E7HngyQIm6SvwCTtKkSzkEso6k5ZcpS?width=2397&height=1611&cropmode=none", 
      // img2: "https://dsm01pap006files.storage.live.com/y4m5HmarQrjEfHTEssII4Eay0cubl9osPQaybRZhJ6Ct3PhhjyF36oh8j-KUrD8oV9d6SGsS5_AZ_1gmSOqU1KEQCR8QHAwS6aGZIjo0zXsBa7Dl3HVnN7MqqhNes73y3NcYyfE7UcNCPqF12egUqjqDYHKHg_ZHc4Z1E7HngyQIm6SvwCTtKkSzkEso6k5ZcpS?width=2397&height=1611&cropmode=none",
      // img3: "https://dsm01pap006files.storage.live.com/y4mA2XqTCKRiwNqaM-OpUyaFPL2rj63OlrrtSLlAAXY624DlybKp0ZbGzugUKGqPjYvV2FO1VU16go7H5UjFj0MJM2TtKEjGoDPRmbwB8_V8GEf8hZ0yi713Yf0Ft_1o2n8riWOpkYuHEeWYFcm82_p3HxkT5BJ6kgtWEVuj7gFRIiA5l1rB43XEL-P-bkbJg0r?width=3024&height=4032&cropmode=none",
      // img4: "https://dsm01pap006files.storage.live.com/y4m9c2AN-g0ujf3Wm_rp_Hk9YDfibZqRsjodMkuzCO7NlgIu7wlzXg1jMYbb8mnlR0aGZxT_dkM94EiKy8bZqIQDb47wmMOCHrQ0BZ6iTqAqNwPUxncV_I8jDTJmbZfsQwyMAEzemRMBm-n6cYetar1UqvaqhVeq0-7--dJx7BfOOzZ63b_cAYvXo5KpLno-Dxi?width=3024&height=4032&cropmode=none",
      // img5: "https://dsm01pap006files.storage.live.com/y4mQcEzKjPRSP-1egM-nODv9qrBww2vEJnDr-dievOfqqpinu1ONmh9vLtb_1gF7XD5g7Ewvl9Ovp2tJfUQEispSG5YYNFisVcTHr5pHyy6NfYTeMRfn07rfe_ofGOhXskNdUQ9Kl-dQCf0y2e3W5aW2I9GV04sWm3jSvB4lBlfTkUL4bYWSw4HdIuMd73WWVUk?width=768&height=1024&cropmode=none",
      // img6: "https://dsm01pap006files.storage.live.com/y4m4FTSohONFvGzvTlx8du7MYL3QnKQn_IB_jnO5aSAtkmZfuSC0Y3HBE9r6m86-ExFKOqW9nz5VCIeq9zUhLZhKBeQnKptLezW0pngcxKgvR6J6vyPIT8nalB5es2k4aKXR7gjLUhG_yXxa9t0k1Ns9nmeu1xc8lm5yWwMIBTpVN1j2lyAE8pXetCR435hAYam?width=768&height=1024&cropmode=none",
      // img7: "https://dsm01pap006files.storage.live.com/y4mDwCI2IDX7ubdF5-cM4Gt9OJkDl6C7E8I9spg2klIELjTkqW_gtgNQyTwvspKRen7NrwUqf_eYmJNC9RCl7X9gOZK7hNBHQb_refDmMmQHoQYlXAulcqbJJsE2FxkR9NJ--RVwxy1vcs9_9q3n-jDKVtGjz3RPCIogl3H-aPoj2VIcP7JDzAf9o2fc1JthmNg?width=600&height=800&cropmode=none",
      // img8: "https://dsm01pap006files.storage.live.com/y4m3pUlWwYo9JTs6nEuJxpZFB-CCtJxXBQIVLHXviSSeiB6UV_qKgTgnRFHFROVf2IPJWYDXtFrh_OSbCVgwZffQtBIaqsWVjwziuqd2_leA7-UMn0GVhOJJXX-fVEn6FBHyss8kNCgs8Ix_8lK6lDwzjqcxBWBL8jgi1ukWgLwLaNqKjFXrpWNg23eXw4BuzqC?width=800&height=600&cropmode=none",
      // img9: "https://dsm01pap006files.storage.live.com/y4m5Rg6RlqiSuTPFzZS4ttT3cFPF1Ugf69LOCC6ayzWyEEvuaVWygvqB_kiCzXPy8ce7m5lfnFxagTnphQJNLf81b7EKWU1ZDZOtuJY839gD0EE1hx2X4VNUZhEyn7exXlRrhebKGG_BHe0tTpGmiolrORFztovEXms5EF4QzVunpfylE2NnV_EvlYqTc3OCyOn?width=800&height=600&cropmode=none",
      // img10: "https://dsm01pap006files.storage.live.com/y4mPx5xb1i6iatef-Bfl9sLsTqx7dflcr3Jnjgn3CHkbUFkvVPL6WHqfWppQxrw7a5bV_rA_DlzOAtl3bRavqn7O-h9xR89q5XfW5tft6HOwiEdFeIBlp-Qbvd7kyZ0Tn5yFMO5Eq4ssuUo7uaQJX_6qf_M0ZMZ8cfDdYgNXQgB01GJPmTAosKS2yo7UInfD7Il?width=800&height=600&cropmode=none"
    }
  }
})
</script>

